import { GoogleMapsService } from "src/app/shared/services/google/google-maps.service";
import {
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  Input,
  NgZone,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GoogleMap } from "@angular/google-maps";
import {
  Geolocation,
  Position as geoLocationPosition,
} from "@capacitor/geolocation";
import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from "capacitor-native-settings";
import { ToastrService } from "ngx-toastr";
import { QuoteCreationService } from "src/app/features/quotes/services/quote-creation.service";
import { Address, GetAddressModel } from "src/app/models/address-model";
import { Position } from "src/app/models/draft-model";
import { AccountService } from "src/app/features/accounts/services/account/account.service";
import { PostalCode } from "src/app/models/zipcode";
import { ZipcodeService } from "src/app/features/myuss/services/zipcode/zipcode.service";
import { ProjectService } from "src/app/features/projects/services/project.service";
import { MatDialog } from "@angular/material/dialog";
import { SiteAddressExistPopupComponent } from "../dialogbox/site-address-exist-popup/site-address-exist-popup.component";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";

@Component({
  selector: "app-project-location",
  templateUrl: "./project-location.component.html",
  styleUrls: ["./project-location.component.scss"],
})
export class ProjectLocationComponent {
  markers: [] = [];
  isNative: boolean;
  shippingAddressForm: FormGroup;
  center!: google.maps.LatLngLiteral;
  zoom = 15;
  markerPosition: google.maps.LatLngLiteral;
  markerLatLang: google.maps.LatLngLiteral;
  isProjectActive = true;
  @Input() screen: string;

  markerOptions: google.maps.MarkerOptions = {
    draggable: true,
  };
  options: google.maps.MapOptions = {
    center: { lat: 37.0902, lng: -95.7129 }, // Set to the approximate center of the United States
  };
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @ViewChild("search")
  public searchElementRef!: ElementRef;
  canDropPin = false;
  addressDisable: boolean = false;
  autocomplete: google.maps.places.Autocomplete;
  position: Position | null = null;
  optionsAuto = {
    fields: ["address_components", "geometry", "icon", "name"],
    strictBounds: false,
  };
  /**
   * Address mapper fields for google places
   */
  addressMapperFields = {
    locality: "short_name",
    administrative_area_level_1: "short_name",
    postal_code: "short_name",
    street_number: "long_name",
    route: "long_name",
    country: "short_name",
    sublocality_level_1: "short_name",
    sublocality: "short_name",
    political:"short_name"
  };

  address = {
    accountId: "",
    city: "",
    country: "",
    shipToAddress: true,
    state: "",
    street: "",
    zipcode: "",
    siteName: "",
    latitude: 0,
    longitude: 0,
    addressId: "",
  } as Address;

  selectedZipcode: string = "";
  latitude: number;
  longitude: number;
  allAddresses: GetAddressModel[] | [] = [];
  isValidAddress: boolean;
  geoLocationPosition: geoLocationPosition | undefined;
  isZipcodeServiceable:boolean=false


  constructor(
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone,
    public toastr: ToastrService,
    private quoteCreationService: QuoteCreationService,
    private projectService: ProjectService,
    private accountService: AccountService,
    private zipcodeservice: ZipcodeService,
    private dialog: MatDialog,
    private profileService: ProfileService,
    private googleMapService: GoogleMapsService,
  ) {
    this.shippingAddressForm = this.fb.group({
      currentLocation: ["", Validators.required],
    });
    effect(()=>{
      const account= profileService.selectedAccount()
      if(account.accountId){
        this.fetchAddresses();
      }
    })
  }

  ngOnInit() {
    this.projectService.setAddress(this.address);
    console.log("address", this.address);
    

    this.googleMapService.getCurrentLocation().then((res) => {
      this.geoLocationPosition = res;
      if (
        this.geoLocationPosition?.coords.latitude &&
        this.geoLocationPosition.coords.longitude
      ) {
        this.center = {
          lat: this.geoLocationPosition.coords.latitude,
          lng: this.geoLocationPosition.coords.longitude,
        };
      }
    });
  }

  fetchAddresses() {
    this.accountService.fetchAddressForCurrentAccount("parent");
    this.accountService.savedAddresses$.subscribe((result) => {
      if (result) {
        const filteredAddresses = result.filter(
          (savedAddress) => savedAddress.isParentAddress
        );
        this.allAddresses = filteredAddresses;
      }
    });
  }

  ngAfterContentChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit() {
    // this.searchElementRef.nativeElement.focus();
    this.autoCompleteInitiate();
    this.autocomplete.setComponentRestrictions({ country: "us" });
  }

  //get current location
  async getCurrentLocation() {
    let position: geoLocationPosition | undefined = this.geoLocationPosition;
    try {
      if (position) {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.zoom = 18;
        this.markerPosition = this.center;
        this.markerLatLang = this.center;
        this.markerOptions = {
          draggable: true,
          animation: google.maps.Animation.DROP,
          position: this.markerPosition,
        };
        await this.reverseGeocode(this.center.lat, this.center.lng);
      } else {
        console.error("No position data available");
      }
    } catch (e: unknown) {
      if (
        e instanceof GeolocationPositionError &&
        e.message === "Location services are not enabled"
      ) {
        await this.openSettings();
      }
    }
  }

  autoCompleteInitiate() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement,
      this.optionsAuto
    );
    this.autocomplete?.addListener("place_changed", () => {
      this.shippingAddressForm
        .get("currentLocation")
        ?.setValue(this.searchElementRef.nativeElement.value);
      this.ngZone.run(() => {
        //get the place result
        let place: google.maps.places.PlaceResult =
          this.autocomplete.getPlace();
        //verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        this.setAddressFromPlace(place);
      });
    });
  }

  getPlaceByAddress(address: string) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === "OK") {
        if (results) {
          this.setAddressFromPlace(results[0]);
        } else {
          this.toastr.error("No results found");
        }
      } else {
        this.toastr.error(
          "Geocoder failed due to: " + status + ". Please try again."
        );
      }
    });
  }

  async getNativePermission(): Promise<boolean> {
    const permissonStatus = await Geolocation.checkPermissions();

    if (permissonStatus.location != "granted") {
      const requestStatus = await Geolocation.requestPermissions();
      if (requestStatus.location != "granted") {
        // this.toastr.error("Please allow location access to continue.");
        await this.openSettings(true);
        return false;
      }
    }
    return true;
  }

  /**
   *
   * @param app if location is not enabled then open settings
   * true: open app settings
   * false: open location settings
   * @returns
   */
  openSettings(app = false) {
    return NativeSettings.open({
      optionAndroid: app
        ? AndroidSettings.ApplicationDetails
        : AndroidSettings.Location,
      optionIOS: app ? IOSSettings.App : IOSSettings.LocationServices,
    });
  }

  async reverseGeocode(lat: number, lng: number) {
    const result: google.maps.places.PlaceResult =
      await this.quoteCreationService.reverseGeocode(lat, lng);
    console.log("reverseGeocode result", result);
    const place: google.maps.places.PlaceResult = result;
    this.setAddressFromPlace(place);
    this.latitude = place?.geometry?.location?.lat() as number;
    this.longitude = place?.geometry?.location?.lng() as number;
    this.center = {
      lat: this.latitude,
      lng: this.longitude,
    };
    this.position = {
      lat: this.latitude,
      lng: this.longitude,
    };
    this.lat_lng(this.position);
    return result;
  }

  // on click of map
  async onMapClick(event: google.maps.MapMouseEvent) {
    await this.onZoomChange();
    if (this.canDropPin) {
      if (!this.addressDisable) {
        this.markerPosition =
          event.latLng?.toJSON() as google.maps.LatLngLiteral;
        this.markerLatLang =
          event.latLng?.toJSON() as google.maps.LatLngLiteral;
        console.log(this.markerPosition);
        this.reverseGeocode(this.markerPosition.lat, this.markerPosition.lng);
        this.markerOptions = {
          draggable: true,
          position: this.markerPosition,
        };
      } else {
        this.toastr.error("Please enter a valid 5-digit ZIP code.");
      }
    } else {
      // Inform the user that the zoom level is not sufficient to drop a pin
      this.toastr.error("Zoom in the map to drop PIN on the location.");
    }
  }

  // on zoom change
  async onZoomChange() {
    // Get the zoom level directly from the map instance
    if (this.map.getZoom()) {
      const currentZoom = this.map.getZoom();
      // Check if the zoom level is 18 or above
      this.canDropPin = currentZoom !== undefined && currentZoom >= 15;
    }
  }

  // on click of marker
  onDragend(event) {
    this.markerPosition = event.latLng.toJSON();
    this.markerLatLang = event.latLng?.toJSON();
    this.markerOptions = {
      draggable: true,
      animation: google.maps.Animation.DROP,
      position: this.markerPosition,
    };
    this.reverseGeocode(this.markerPosition.lat, this.markerPosition.lng);
  }

  setAddressFromPlace(place: google.maps.places.PlaceResult) {
    const location = place?.geometry?.location;
    console.log("place", place);

    if (
      location &&
      typeof location.lat === "function" &&
      typeof location.lng === "function"
    ) {
      this.address.city = "";
      this.address.state="";
      this.address.zipcode="";
      this.selectedZipcode="";
      this.address.country="";
      this.lat_lng({ lat: location.lat(), lng: location.lng() });
      let showAddress = "";
      let route = "";
      let street_number = "";
      let range = place?.address_components?.length || 0;

      for (let i = 0; i < range; i++) {
        let addressType = place.address_components
          ? place.address_components[i].types[0]
          : "";
        if (this.addressMapperFields[addressType]) {
          let val = place.address_components
            ? place.address_components[i][this.addressMapperFields[addressType]]
            : "";
          console.log("val", val, addressType);
          switch (addressType) {
            case "street_number":
              street_number = val;
              break;
            case "route":
              route = val;
              break;
            case "locality":
              this.address.city = val;
              break;
              case "political":
            case "sublocality":
            case "sublocality_level_1": // Not needed in this case since locality exists
              if (!this.address.city) {
                this.address.city = val;
              }
              break;
            case "administrative_area_level_1":
              this.address.state = val;
              break;
            case "postal_code":
              this.selectedZipcode = val?.slice(0, 5);
              this.address.zipcode = val?.slice(0, 5);
              break;
            case "country":
              this.address.country = val;
              break;
            default:
              break;
          }
        }
      }
      console.log("projectInfo ", this.address);
      showAddress =
        street_number && route
          ? street_number + " " + route
          : this.address.zipcode;
      showAddress =
        showAddress +
        ", " +
        this.address.city +
        ", " +
        this.address.state +
        ", " +
        this.address.zipcode +
        ", " +
        this.address.country;

      this.address.street =
        street_number && route
          ? street_number + " " + route
          : this.address.zipcode;
      console.log("Address strert@@", this.address.street);
      this.address.siteName = this.address.street;
      //set latitude, longitude
      this.address.latitude = place.geometry?.location?.lat();
      this.address.longitude = place.geometry?.location?.lng();
      this.shippingAddressForm.patchValue({
        currentLocation: showAddress,
      });

      // Update address signal
      this.address.addressId = "";
      this.projectService.setAddress(this.address);
      if (this.screen === "quoteDetails_V2") {
        this.checkDuplicateAddress(
          this.profileService.selectedAccount().accountId as string,
          this.address.street
        );
        this.checkZipcode(this.address.zipcode);
      }
    } else {
      console.error(
        "Invalid or missing geometry.location in place object:",
        place
      );
    }
  }

  lat_lng(event) {
    this.center = {
      lat: event.lat,
      lng: event.lng,
    };
    this.position = {
      lat: event.lat,
      lng: event.lng,
    };
    this.markerOptions = {
      draggable: true,
      position: this.position,
      animation: google.maps.Animation.DROP,
    };
    this.zoom = 18;
    this.markerPosition = this.position;
  }

  selectedAddress(address, checkZipcode: boolean = true) {
    this.address = address;
    this.shippingAddressForm.patchValue({
      currentLocation:
        address.street +
        ", " +
        address.city +
        ", " +
        address.state +
        ", " +
        address.zipcode,
    });
    this.center = {
      lat: address.latitude,
      lng: address.longitude,
    };
    this.position = {
      lat: address.latitude,
      lng: address.longitude,
    };
    this.markerOptions = {
      draggable: true,
      position: this.position,
      animation: google.maps.Animation.DROP,
    };
    this.zoom = 18;
    this.markerPosition = this.position;
    this.projectService.setAddress(this.address);
    console.log("address", this.address);
    if (this.screen === "quoteDetails_V2" && checkZipcode) {
      this.checkZipcode(this.address.zipcode);
    }
  }

  // check zipcode validation
  checkZipcode(zipcode: string) {
    this.zipcodeservice
      .zipcodeValidation({ postalCode: zipcode })
      .subscribe((result) => {
        console.log("zipcode validation result", result);
        if(result?.status==1000){
        this.isZipcodeServiceable=true
      }
      if (result?.status != 1000) {
          this.isZipcodeServiceable=false
        this.projectService.setAddress({} as Address);
        }
        console.log("address", this.address);
      });
  }

  // check duplicate address
  checkDuplicateAddress(userId: string, enteredAddress: string): void {
    if(!enteredAddress){
      return
    }
    this.quoteCreationService
      .checkDuplicateAddress(userId, enteredAddress)
      .subscribe((data) => {
        if (data) {
          if (data.isDuplicate) {
            this.openSiteAddressExistPopup(data.addressInfo.addressId);
          }
        }
      });
  }

  // Method to open the Site Address Exist Popup
  openSiteAddressExistPopup(addressId: string) {
    const dialogRef = this.dialog.open(SiteAddressExistPopupComponent, {
      minWidth: "30%",
      disableClose: true,
      data: addressId,
    });

    // You can subscribe to the 'afterClosed' event if you need to perform actions after the popup is closed
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.action === "choose") {
          this.selectedAddressById(result.addressId, false);
        }
      }
    });
  }

  selectedAddressById(Id: string, checkZipcode: boolean = true) {
    const selectedAddress = this.allAddresses?.find(
      (address) => address.addressId === Id
    );
    this.selectedAddress(selectedAddress, checkZipcode);
  }
}
