import { Injectable } from "@angular/core";
import { CaseProductInfo, CaseModel } from "src/app/models/case-details-model";
import { ColumnData } from "src/app/models/order-model";
import {
  AggregatedSiteInfo,
  AggregatedSiteInfoBundle,
  LocationModel,
} from "src/app/models/order-change.model";
import { Address } from "src/app/models/address-model";

@Injectable({
  providedIn: "root",
})
export class OrderChangeService {
  allUnitsChecked: boolean = false;
  effectiveDate: Date | null;
  unitNumbers: string;
  additionalInstructions: string;
  selectedBundlesBySite: LocationModel[] = [];
  aggregatedSiteInfo: AggregatedSiteInfo[] = [];

  constructor() {}

  formatCaseSummary(
    requestType:
      | "Move"
      | "Change Service Frequency"
      | "Change Quantity - Add Units"
      | "Change Quantity - Reduce Units"
      | "Add New Unit Type",
    caseDataInput: CaseModel,
    effectiveDateFormattedForNotes: string,
    selectedSite?: Address,
    reviewOnly?: string,
    pickupAllunits?: boolean
  ): string {
    let caseData = { ...caseDataInput };
    caseData.selectedDate =
      effectiveDateFormattedForNotes || caseData.selectedDate;
    switch (requestType) {
      case "Move":
        return this.formatMoveCaseSummary(caseData);

      case "Change Service Frequency":
        return this.formatFrequencyChangeSummary(
          caseData,
          effectiveDateFormattedForNotes
        );

      case "Change Quantity - Add Units":
        return this.formatQuantityChangeSummary(
          "Increase",
          caseData,
          selectedSite,
          reviewOnly
        );

      case "Change Quantity - Reduce Units":
        return this.formatQuantityChangeSummary(
          "Decrease",
          caseData,
          selectedSite,
          reviewOnly,
          pickupAllunits
        );

      case "Add New Unit Type":
        return this.formatAddUnitTypeSummary(caseData);

      default:
        return "";
    }
  }

  formatMoveCaseSummary(caseData: CaseModel): string {
    let summary = `
    Move:
    Effective Date: ${caseData.selectedDate}
    Asset Serial Numbers: ${caseData.unitNumbers.join(",")}
    To: ${caseData.siteAddress}
    Additional Information: ${caseData.placementNote} \n
    Details -`;
    // for each site in the case data, add a line to the summary
    this.aggregateSelectedBundles("Move");
    let siteCount = 0;
    this.aggregatedSiteInfo.forEach((site) => {
      siteCount++;
      // add an extra line break between sites
      if (siteCount > 1) {
        summary += "\n";
      }
      summary += `
      From: ${site.nameAndSiteName} (${site.placementNote})`;
      // for each product in the site, add a line to the summary
      site.bundlesToUpdate.forEach((bundle) => {
        summary += `
        (${bundle.quantity}) ${bundle.assetName} | ${
          bundle.currentServiceFrequency
        } 
        Unit Numbers: ${bundle.unitNumbers
          .filter((unitNumber) => unitNumber != "")
          .join(", ")}`;
      });
    });
    return summary;
  }

  formatQuantityChangeSummary(
    changeType: "Increase" | "Decrease",
    caseData: CaseModel,
    selectedSite?: Address,
    reviewOnly?: string,
    pickupAllunits?: boolean
  ): string {
    // for quantity changes there will only be one product in the description data
    const descriptionData = caseData.productInfo
      ? JSON.parse(caseData.productInfo)
      : null;
    let unitType = "";
    if (
      descriptionData &&
      descriptionData.Data &&
      descriptionData.Data.length > 0
    ) {
      const product = descriptionData.Data[0];
      unitType = `(${product.Quantity}) ${product.Product} | ${
        product["Service Frequency"]
      } | ${this.formatAncillaryServices(product)}`;
    }
    let location = `${selectedSite?.siteName} (${selectedSite?.placementNotes})`;
    //if pickup all units is true and changeType is decrease
    if (pickupAllunits && changeType === "Decrease") {
      unitType = "Pickup All Units";
      location = caseData?.siteAddress;
    }
    let summary = `
      ${reviewOnly ? reviewOnly : ""}
      Change Quantity - ${changeType}
      Unit Type: ${unitType}
      Location: ${location}
      Effective Date: ${caseData.selectedDate}`;
    // if it's a pickup, include pickup reason and unit numbers
    if (changeType === "Decrease") {
      summary += `
        Unit Numbers: ${caseData.unitNumbers.join(",")}`;
    }

    summary += `
      Notes: ${caseData.placementNote}`;
    return summary;
  }

  formatAddUnitTypeSummary(caseData: CaseModel): string {
    let summary = `
      Add New Unit Type:
      Effective Date: ${caseData.selectedDate}  
      Location: ${caseData.siteAddress}  
      Additional Instructions: ${caseData.placementNote} \n
      Details -`;
    // for each site in the case data, add a line to the summary
    const descriptionData = caseData.productInfo
      ? JSON.parse(caseData.productInfo)
      : null;
    if (
      descriptionData &&
      descriptionData.Data &&
      descriptionData.Data.length > 0
    ) {
      descriptionData.Data.forEach((product) => {
        summary += `
          (${product.Quantity}) ${product.Product} | ${
          product["Service Frequency"]
        } | ${this.formatAncillaryServices(product)}`;
      });
    }
    return summary;
  }

  formatAncillaryServices(product: CaseProductInfo): string {
    let ancillaryServices = "No Add Ons";
    if (product.AncillaryServices && product.AncillaryServices.length > 0) {
      ancillaryServices = product.AncillaryServices.map(
        (service) => service.ancillaryServiceName
      ).join(",");
    }
    return ancillaryServices;
  }

  formatFrequencyChangeSummary(
    caseData: CaseModel,
    effectiveDateFormattedForNotes: string
  ): string {
    let summary = `
        Change Service Frequency -
        Effective Date: ${effectiveDateFormattedForNotes}\n`;
    // if all units are checked, then put that in the summary
    if (this.allUnitsChecked) {
      summary += `Units: All Units on Site\n`;
    } else {
      summary += `Units: ${caseData.unitNumbers}\n`;
    }
    summary += `Additional Instructions: ${caseData.placementNote} \n
        Details -`;
    // for each site in the case data, add a line to the summary
    // TODO: calculate the price
    this.aggregateSelectedBundles("FrequencyChange");
    let siteCount = 0;
    this.aggregatedSiteInfo.forEach((site) => {
      siteCount++;
      // add an extra line break between sites
      if (siteCount > 1) {
        summary += "\n";
      }
      summary += `
          Location: ${site.nameAndSiteName} (${site.placementNote})`;
      // for each product in the site, add a line to the summary

      //if isServiceContractedPrice===true then don't show the price per service
      site.bundlesToUpdate.forEach((bundle) => {
        let pricePerService: string = bundle.isServiceContractedPrice
          ? ""
          : `> @$${bundle.pricePerService}/svc`;
        pricePerService = bundle.pricePerService == 0 ? "" : pricePerService;

        pricePerService = bundle.pricePerService == 0 ? "" : pricePerService;

        summary += `
            (${bundle.quantity}) ${bundle.assetName} at ${
          bundle.currentServiceFrequency
        } > ${bundle.changeFrequencyTo} ${pricePerService}
            Unit Numbers: ${bundle.unitNumbers
              .filter((unitNumber) => unitNumber != "")
              .join(", ")}`;
      });
    });
    return summary;
  }

  aggregateSelectedBundles(changeType: "FrequencyChange" | "Move"): void {
    const aggregatedSiteInfo: AggregatedSiteInfo[] = [];
    this.selectedBundlesBySite.forEach((site) => {
      // get the site info from the first bundle
      const firstBundle = site.bundleArray[0];
      const siteInfo = {
        nameAndSiteName: firstBundle.siteAddress,
        placementNote: firstBundle.placementNote,
        jobsiteId: firstBundle.jobsiteId,
        bundlesToUpdate: new Array(),
      } as AggregatedSiteInfo;
      site.bundleArray.forEach((bundle) => {
        const bundleIdx = siteInfo.bundlesToUpdate.findIndex((b) => {
          let match = false;
          switch (changeType) {
            case "FrequencyChange":
              match =
                b.assetName === bundle.assetName &&
                b.changeFrequencyTo === bundle.changeFrequency &&
                b.currentServiceFrequency === bundle.serviceName;

              break;
            case "Move":
              match =
                b.assetName === bundle.assetName &&
                b.currentServiceFrequency === bundle.serviceName;
              break;
          }
          return match;
        });
        if (bundleIdx === -1) {
          const newBundle = {
            assetName: bundle.assetName,
            quantity: 1,
            currentServiceFrequency: bundle.serviceName,
            changeFrequencyTo: bundle.changeFrequency,
            unitNumbers: [bundle.unitNumber],

            //calculate and list per service price @$${product.Price}/svc
            isServiceContractedPrice: bundle.isServiceContractedPrice,
            pricePerService:
              bundle.servicePrice == 0
                ? 0
                : bundle.servicePrice / bundle.numberOfServices,
          } as AggregatedSiteInfoBundle;
          siteInfo.bundlesToUpdate.push(newBundle);
        } else {
          // if the bundle is already in the list, increment the quantity and add unit number
          siteInfo.bundlesToUpdate[bundleIdx].quantity++;
          siteInfo.bundlesToUpdate[bundleIdx].unitNumbers.push(
            bundle.unitNumber
          );
        }
      });
      aggregatedSiteInfo.push(siteInfo);
    });
    this.aggregatedSiteInfo = aggregatedSiteInfo;
  }
}
