import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from "@capacitor/push-notifications";
import { UssApiService } from "../uss-api/uss-api.service";
import { catchError, map } from "rxjs";
import { UserProfile } from "src/app/models/profile-model";
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { Toast, ToastrService } from "ngx-toastr";
@Injectable({
  providedIn: "root",
})
export class PushNotificationService {
  selectedAccountId: string | undefined;

  constructor(
    private router: Router,
    private api: UssApiService,
    private toaster: ToastrService
  ) {}

  // Method to initialize push notifications
  async initPushNotifications(
    selectedAccount: Partial<UserProfile>
  ): Promise<void> {
    try {
      const permStatus = await PushNotifications.checkPermissions();
      if (permStatus.receive === "prompt") {
        const requestPermission = await PushNotifications.requestPermissions();
        if (requestPermission.receive !== "granted") {
          throw new Error("User denied push notification permissions.");
        }
      } else if (permStatus.receive !== "granted") {
        throw new Error("Push notification permission not granted.");
      }

      await this.registerNotifications();
      await this.addListeners(selectedAccount);
    } catch (error) {
      console.error("Error initializing push notifications:", error);
    }
  }

  private async registerNotifications(): Promise<void> {
    await PushNotifications.register();
  }

  private async addListeners(
    selectedAccount: Partial<UserProfile>
  ): Promise<void> {
    const tokenResult = await FirebaseMessaging.getToken();
    this.selectedAccountId = selectedAccount.accountId;

    this.sendFcmTokenToServer({
      fcmToken: tokenResult.token,
      ussPortalUserId: selectedAccount.accountId,
      emailId: selectedAccount.email,
    }).subscribe();

    await PushNotifications.addListener(
      "registration",
      async (token: Token) => {}
    );

    // Listener for receiving a notification
    await PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        console.log("PushNotificationReceivedInForeground:", notification);
      }
    );

    // Listener for notification action performed
    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification: ActionPerformed) => {
        this.router.navigateByUrl(`cases/case-details/${notification.notification.data.caseId}`);
      }
  
    );

    await PushNotifications.addListener("registrationError", (err) => {
      console.error(`Registration Error: ${err}`);
    });
  }

  getDeliveredNotifications = async () => {
    const notificationList =
      await PushNotifications.getDeliveredNotifications();
    console.log(
      "DeliveredNotifications:",
      JSON.stringify(notificationList, null, 2)
    );
  };

  sendFcmTokenToServer(data: {
    fcmToken: string;
    ussPortalUserId: string | undefined;
    emailId: string | undefined;
  }) {
    return this.api
      .post(`accounts/${this.selectedAccountId}/update-fcm-token`, data)
      .pipe(
        map((res) => res["data"]),
        catchError((err) => {
          console.error(`Error updating FCM token: ${JSON.stringify(err)}`);
          throw err;
        })
      );
  }

  getPushNotifications() {
    return this.api
      .get(`notifications/${this.selectedAccountId}/get-notifications`)
      .pipe(
        map((res) => res["data"]),
        catchError((err) => {
          console.error(`Error updating FCM token: ${JSON.stringify(err)}`);
          throw err;
        })
      );
  }

  displayNotification(notification: PushNotificationSchema) {
    this.toaster.success(notification.body, notification.title,);
  }
}
